const en = {
  jinchanzi: 'Golden Cicada',
  ranking: 'Ranking',
  airdrop: 'Airdrop',
  friend: 'Friends',
  quest: 'Quest',

  title: 'current pool',
  kechanchu: 'Can be produced ',
  lasttime: 'remaining output time',
  kewa: 'diggable',
  kewa_unit: 'time',
  jihuo_btn: 'Activation Address',
  success: 'Successful operation',
  jihuo_warning1: 'The address has not been activated',
  jihuo_warning2: 'undercount',
  meta_warning: 'Please connect wallet',

  toRecmond: 'Become a reference',
  toRecmond_tips: 'To become a referral for your friend, add their referral link. After that, you will be registered as their reference. It is important to note that this action is irreversible.',
  toRecmond_add: 'Append',
  toRecmond_warning: 'Please enter the binding address',

  withdraw_list: 'Withdraw List',
  yiwaqu: 'mined',
  ketiqu: 'Can be extracted',
  yitiqu: 'Extracted',
  tiqu: 'Withdraw',
  nodata: 'No data available',
  tiqu_warning: 'Please extract from the DAPP',

  kc_rank: 'Pool ranking',
  yzxm_rank: 'Quality project ranking',
  yzsq_rank: 'Quality community ranking',
  chanchu: 'output',
  zan: 'like',
  totai: 'Total',
  total: 'Total supply',
  sqrs: 'Community',

  xmxq: 'PROJECT DETAILS',
  zcc: 'Gross output',
  website: 'Official website',
  twitter: 'Twitter',
  telegram: 'Telegram',
  coin_total: 'Supply',
  coin_price: 'Price',
  coin_pool: 'Pairs',
  remark: 'Project profile',
  zrs: 'headcount',

  yqhy: 'Invite friends',
  yqhy_tips: 'Both you and your friends can get rewards',
  yqhy_bind: 'Bind friends',
  yqhy_bind_tips: 'You and your friends can get it',
  bind_btn: "Start Binding",
  friend_list: 'Your friends list',
  yqnhy: 'Invite your friends',
  copy_success: 'Successful replication',

  task: 'TASK LIST',
  gksp: 'Watch the video',
  gkyfz: 'Watch the video in one minute',

  tips1: 'to ',
  tips2: ' address Transfer ',
  tips3: ' to activate the mining address and enjoy the platform dividend forever',
  confirm: "confirm",
  copyContract: "copy contract"
}
export default en
