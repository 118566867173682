const zh = {
  jinchanzi:'金蝉子',
  ranking:'排行',
  airdrop:'空投',
  friend:'好友',
  quest:'任务',

  title: '本期矿池',
  kechanchu:'可产出',
  lasttime:'剩余产出时间',
  kewa:'可挖',
  kewa_unit:'次',
  jihuo_btn:'激活地址',
  success:'操作成功',
  jihuo_warning1:'地址还未激活',
  jihuo_warning2:'次数不足',
  meta_warning:'请连接钱包',

  toRecmond:'成为推荐人',
  toRecmond_tips:'要成为您朋友的推荐人，请添加他们的推荐链接。之后，您将被注册为他们的推荐人。重要的是要注意，此操作是不可逆转的。',
  toRecmond_add:'添加',
  toRecmond_warning:'请输入绑定地址',

  withdraw_list:'提取列表',
  yiwaqu:'已挖取',
  ketiqu:'可提取',
  yitiqu:'已提取',
  tiqu:'提取',
  nodata:'暂无数据',
  tiqu_warning:'请从DAPP中提取',

  kc_rank:'矿池排行',
  yzxm_rank:'优质项目排行',
  yzsq_rank:'优质社区排行',
  chanchu:'产出',
  zan:'点赞',
  totai:'总发行量',
  total:'总供应量',
  sqrs:'社区人数',

  xmxq:'项目详情',
  zcc:'总产出',
  website:'官网',
  twitter:'推特',
  telegram:'电报',
  coin_total:'代币总量',
  coin_price:'代币价格',
  coin_pool:'代币池子',
  remark:'项目简介',
  zrs:'总人数',

  yqhy:'邀请好友',
  yqhy_tips:'你和你的朋友都可以获得奖励',
  yqhy_bind:'绑定好友',
  yqhy_bind_tips:'你和好友都可以获得',
  bind_btn:"开始绑定",
  friend_list:'你的好友列表',
  yqnhy:'邀请你的好友',
  copy_success:'复制成功',

  task:'任 务 列 表',
  gksp:'观看视频',
  gkyfz:'观看一分钟的视频',

  tips1:'向',
  tips2:'地址转入',
  tips3:'激活挖矿地址，永久享受平台红利',
  confirm:"确认",
  copyContract:"复制合约"
  
};
export default zh;
