import { TOKENABI, MINT_ABI } from "./abi";
import { Toast } from "vant";
import store from "@/store";
import Web3 from "web3";
let web3Provider;
export let web3js;
let { ethereum } = window;
export let selectAddress;
let gasPrice = "3000000000";

import { get_user_address } from "@/api";

if (ethereum) {
  web3Provider = ethereum;
} else if (window.web3) {
  web3Provider = web3.currentProvider;
} else {
  web3Provider = new Web3.providers.HttpProvider("http://localhost:8545");
}
web3js = new Web3(web3Provider);
// let gw = Web3.utils.toWei('1', "Gwei")
web3js.eth.getGasPrice().then((res) => {
  gasPrice = res;
});

let userId = "";
console.log(window.Telegram);
//初始化 Web App 环境
if (window.Telegram && window.Telegram.WebApp) {
  window.Telegram.WebApp.ready();
  // 获取用户信息
  const user = window.Telegram.WebApp.initDataUnsafe;
  const startParam = user?.start_param;
  if (startParam) {
    localStorage.setItem("startParam", startParam);
  }
  if (user && JSON.stringify(user) !== "{}") {
    console.log(user, "useruseruser");
    userId = user.user.id;
    store.commit("SET_TELE_USER_ID", userId);
    // this.user = user.user; //可以获取其他用户信息，如 username、first name 等
    console.log(userId, "this.user");
  } else {
    console.log("No user data available");
  }
}

export async function linkMyWallet() {
  if (userId) {
    return new Promise((resolve) => {
      get_user_address({
        user_id: userId,
      })
        .then((res) => {
          console.log(res.data);
          store.commit("SET_ADDRESS", res.data);
          selectAddress = res.data;
          contractInstanceInit();
          resolve(res.data);
        })
        .catch((err) => {
          Toast(err.msg);
        });
    });
  } else {
    return new Promise(function (resolve, reject) {
      try {
        ethereum
          .request({
            method: "eth_requestAccounts",
          })
          .then(function (accounts) {
            selectAddress = accounts[0];
            store.commit("SET_ADDRESS", selectAddress);
            contractInstanceInit();
            resolve(selectAddress);
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {
        Toast("Please Install MetaMask!");
        store.commit("SET_ADDRESS", "-1");
        resolve("-1");
        return;
      }
      //web3js监听metamask地址切换
      ethereum.on("accountsChanged", function (accounts) {
        //   console.log(accounts, 'accountsChanged')
        // selectAddress = accounts[0];
        // store.commit("SET_ADDRESS", selectAddress);
        // contractInstanceInit();
        // resolve(selectAddress);
        location.reload()
      });
      // 监听网络切换
      ethereum.on("networkChanged", function (networkIDstring) {
        // contractInstanceInit();
        location.reload()
      });
    });
  }
}

// 合约
export const mintContract = "0x550E4dcb35c2618634a228812c0Bb4C664788448";
let mintContractInstance;
// const tokenContract = "0xB1727bB771884045cBA8AC447fEC4b37345300ba"; //0x55d398326f99059fF775485246999027B3197955
// let tokenContractInstance;

let rewardFee = 0;

export const contractInstanceInit = function () {
  return new Promise((resolve) => {
    mintContractInstance = new web3js.eth.Contract(MINT_ABI, mintContract);
    // tokenContractInstance = new web3js.eth.Contract(TOKENABI, tokenContract);

    mintContractInstance.methods
      .getDrawRewardFee()
      .call()
      .then((res) => {
        console.log(res, "rewardFee");
        rewardFee = res;
      })
      .catch((err) => {});
    resolve();
  });
};

// // 代币授权
// export const approveToken = function (backFunction, errorFunction) {
//   tokenContractInstance.methods
//     .approve(mintContract, "999999999999000000000000000000000000")
//     .send(
//       {
//         from: selectAddress,
//         gasPrice: gasPrice,
//       },
//       errorFunction
//     )
//     .then(backFunction);
// };
// // 检查是否授权
// export const allowToken = function (backFunction, errorFunction) {
//   tokenContractInstance.methods
//     .allowance(selectAddress, mintContract)
//     .call(
//       {
//         from: selectAddress,
//       },
//       errorFunction
//     )
//     .then(backFunction);
// };

// 激活账户
export const activeAddress = (amount) => {
  return new Promise((resolve, reject) => {
    mintContractInstance.methods
      .activeAddress()
      .send({
        from: selectAddress,
        value: amount,
        gasPrice: gasPrice,
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// 获取激活费用
export const getActiveFee = function () {
  return new Promise((resolve, reject) => {
    mintContractInstance.methods
      .getActiveFee()
      .call()
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
// 获取地址激活状态
export const addressIsActive = function () {
  return new Promise((resolve, reject) => {
    mintContractInstance.methods
      .addressIsActive(selectAddress)
      .call()
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
// 提取奖励
export const drawReward = (user, token) => {
  return new Promise((resolve, reject) => {
    mintContractInstance.methods
      .drawReward(user, token)
      .send({
        from: selectAddress,
        gasPrice: gasPrice,
        value: rewardFee,
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
