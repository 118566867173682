import request from "../utils/request.js";
import qs from "qs";
// 获取用户信息接口 （DAPP系统载入时）
export const get_user_info = (data) => {
  return request.post("/api/get_user_info", qs.stringify(data));
};
// 初始化地址接口 （DAPP系统载入时）
export const init_account = (data) => {
  return request.post("/api/init_account", qs.stringify(data));
};
// 获取本期矿池列表接口（首页）
export const get_pool_list_home = (data) => {
  return request.post("/api/get_pool_list_home", qs.stringify(data));
};
// 首页获取矿池信息接口
export const get_pool_info = (data) => {
  return request.post("/api/get_pool_info", qs.stringify(data));
};
// 获取今日挖矿剩余次数接口
export const get_mint_rest = (data) => {
  return request.post("/api/get_mint_rest", qs.stringify(data));
};
// 点击挖矿接口
export const mint_token = (data) => {
  return request.post("/api/mint_token", qs.stringify(data));
};
// 获取用户平台币奖励接口
export const get_main_reward = (data) => {
  return request.post("/api/get_main_reward", qs.stringify(data));
};
// 获取用户平台币奖励接口
export const get_user_reward = (data) => {
  return request.post("/api/get_user_reward", qs.stringify(data));
};
// 获取矿池列表接口（排行页面）
export const get_pool_list = (data) => {
  return request.post("/api/get_pool_list", qs.stringify(data));
};
// 获取矿池详情接口（排行页面）
export const get_pool_detail = (data) => {
  return request.post("/api/get_pool_detail", qs.stringify(data));
};
// 点赞矿池接口（排行页面）
export const like_pool = (data) => {
  return request.post("/api/like_pool", qs.stringify(data));
};
// 取消点赞矿池接口（排行页面）
export const cancel_like_pool = (data) => {
  return request.post("/api/cancel_like_pool", qs.stringify(data));
};
//管理员设置奖励接口
export const admin_set_reward = (data) => {
  return request.post("/api/admin_set_reward", qs.stringify(data));
};
//完成任务奖励挖矿次数接口
export const add_task_count = (data) => {
  return request.post("/api/add_task_count", qs.stringify(data));
};

//获取推广项目列表接口
export const get_item_list = (data) => {
  return request.post("/api/get_item_list", qs.stringify(data));
};
// 获取推广项目详情接口
export const get_item_detail = (data) => {
  return request.post("/api/get_item_detail", qs.stringify(data));
};
// 点赞推广代币接口
export const like_item = (data) => {
  return request.post("/api/like_item", qs.stringify(data));
};
// 取消点赞推广项目接口
export const cancel_like_item = (data) => {
  return request.post("/api/cancel_like_item", qs.stringify(data));
};

//获取推广社区列表接口
export const get_team_list = (data) => {
  return request.post("/api/get_team_list", qs.stringify(data));
};
// 获取推广社区详情接口
export const get_team_detail = (data) => {
  return request.post("/api/get_team_detail", qs.stringify(data));
};
// 点赞推广社区接口
export const like_team = (data) => {
  return request.post("/api/like_team", qs.stringify(data));
};
// 取消点赞推广社区接口
export const cancel_like_team = (data) => {
  return request.post("/api/cancel_like_team", qs.stringify(data));
};
// 提取列表
export const get_draw_list = (data) => {
  return request.post("/api/get_draw_list", qs.stringify(data));
};
// 绑定下级
export const binding_lower = (data) => {
  return request.post("/api/binding_lower", qs.stringify(data));
};
// 绑定上级
export const binding_parent = (data) => {
  return request.post("/api/binding_parent", qs.stringify(data));
};
// 获取好友（下级地址）列表接口
export const get_lower_list = (data) => {
  return request.post("/api/get_lower_list", qs.stringify(data));
};
// 获取平台代币详情接口
export const get_main_token = (data) => {
  return request.post("/api/get_main_token", qs.stringify(data));
};

export const get_user_address = (data) => {
  return request.post("/api/get_user_address", qs.stringify(data));
};

// 首页获取矿池已挖取奖励接口
export const get_mint_reward = (data) => {
  return request.post("/api/get_mint_reward", qs.stringify(data));
};