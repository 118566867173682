import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/assets/style.scss";
import i18n from "./utils/i18n";
Vue.config.productionTip = false;
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import { Loading, Popup } from "vant";
import { linkMyWallet } from "@/contract";
import { init_account } from "@/api";
Vue.use(Loading);
Vue.use(Popup);
// 简单配置
NProgress.inc(0.2);
NProgress.configure({
  easing: "ease",
  speed: 500,
  showSpinner: false,
});

router.beforeEach(async (to, from, next) => {
  NProgress.start();
  if (!store.state.user.accountAddress) {
    try {
      let address = await linkMyWallet();
      let userData = await store.dispatch("loadUserData");
      console.log(userData,'userData');
      if(!userData.Id){
        init_account({ address: address }).then(() => { }).catch(err => { });
      }    
      
    } catch (err) { }
  }
  next();
});

router.afterEach(() => {
  NProgress.done();
});
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
